@include media(xl) {
    $s2: $space * 2;
    $s4: $space * 4;

    .m-add-to-cart-form-v2 {
        &__price-quantity {
            margin-bottom: $s2;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: row;
        }

        &__price {
            margin-bottom: 0;
        }
        &__stock-quantity {
            margin: $s4 0 $s2 0;
            max-height: 48px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
        }

        &__stock {
            width: 100%;
            margin-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1201";