@include media(lg) {
    .t-panel {
        margin-left: 0;
        margin-right: 0;

        &__header {
            &--stretched {
                padding-left: unset;
                padding-right: unset;
                border-top: none;
                @include border($where: bottom);
            }

            &--title {
                font-size: $font-size--xlarge;
                line-height: $line-height--xlarge;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1447";