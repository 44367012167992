/* ------------------------------------ *\
    SHOPPING LIST WIDGET
\* ------------------------------------ */

.shopping-list-widget {
    position: relative;

    &__list-link {
        @include border(bottom);
        color: $base-text-color;
        overflow: hidden;
        padding: ($space * 2) ($space * 2) ($space * 2) ($space * 6);
        position: relative;
        text-overflow: ellipsis;
        width: 100%;

        &::before {
            content: $contorion-icon--wishlist-icon;
            font-family: $contorion-font-family;
            height: 10px;
            left: 10px;
            position: absolute;
            width: 10px;
        }

        &:hover {
            color: $theme-color;
            text-decoration: none;
        }
    }

    &__list {
        max-height: 140px;
        overflow: scroll;
    }

    &__wrapper {
        position: absolute;
        margin-top: $space;
        z-index: $z-modal;

        .tooltip {
            width: 290px;
            padding: $space * 2;

            &::before { left: 50%; }
            &::after { left: calc(50% - 1px); }
        }

        .tooltip__header .fa {
            position: absolute;
            top: 12px;
            right: 10px;
        }

        .tooltip__form {
            position: relative;

            & > input { padding-right: 82px; }

            & > .button {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    &--small {
        position: relative;
        margin-right: $space * 2;

        .button--shopping-list.active {
            color: $theme-color;
        }

        @include media(md) {
            .slider__content & {
                margin-right: 0;
            }
        }

        @include media(xxl) {
            .slider__content & {
                margin-right: $space * 2;
            }
        }
    }

    [class^="icon--"] {
        font-size: 16px;
    }

    &.inProgress .shopping-list-widget__list::after,
    &.inProgress .tooltip__form::after {
        background-color: rgba(255, 255, 255, 0.6);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__item:hover {
        background-color: $grey--light;
    }

    &--cart {
        width: 100%;

        @include media(md) {
            width: initial;
        }
    }
}

.slider {
    .shopping-list-widget--small {
        display: none;
    }

    &.slider--no-buttons .shopping-list-widget--small {
        display: block;
    }

    @include media(lg) {
        .shopping-list-widget--small {
            display: block;
        }
    }
}

.new-shopping-list {
    box-shadow: $shadow1;
    background-color: $grey--medium;

    .slider__button-right,
    .slider__button-left {
        border: 0;
        box-shadow: none;
        background: transparent;
    }

    &__details {
        height: auto;
        overflow: hidden;

        &.collapsed {
            max-height: 0;
            padding: 0;
        }
    }
    &__sub-header {
        padding-top: $space * 2;
        padding-bottom: $space * 2;
        @include border($where: bottom, $color: $grey--light);

        @include media(xxl) {
            display: flex;
            justify-content: space-between;
            padding-top: $space * 5;
            padding-bottom: $space * 5;
        }
    }

    &__tabs-slider {
        padding-left: $space * 10;
        padding-right: $space * 10;
    }

    &__tab {
        color: $theme-color;
        background-color: white;
        border: 0;
        border-radius: 2px 2px 0 0;
        white-space: nowrap;
        cursor: default;

        &.collapsed {
            background-color: $grey--medium;
            color: black;
            border-radius: 0;
            cursor: pointer;

            .fa-pencil {
                display: none;
            }
        }
    }

    &__remove-button {
        position: absolute;
        justify-content: right;
        margin-left: -$space * 4;
        margin-top: $space;
        z-index: 1;
        color: $grey;
    }

    &__actions-wrapper {
        display: flex;
        justify-content: space-between;

        @include media(lg) {
            display: block;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;

        @include media(lg) {
            justify-content: flex-start;
        }
    }

    .tab__content {
        min-height: 300px;

        .fa-spin-content {
            color: $theme-color;
            font-size: 30px;
            left: calc(50% - 21px);
            position: relative;
            top: 129px;
            -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear;
        }
    }
}

.name-editing {
    @include border($color: $blue);
}

.shopping-list--out-of-stock {
    .button__text {
        color: $grey--darker;
        font-weight: normal;
        margin-left: 0;
        white-space: normal;
    }
}

;@import "sass-embedded-legacy-load-done:559";