.page-cancel-ordercancel {
    &.no-js {
        .order-cancellation__step-2 {
            margin-top: $space * 2;
        }
    }

    .progress-bar__step-text {
        color: $grey--darker;
    }

    .progress-bar__step.active {
        .progress-bar__step-text {
            color: $grey--darker;
        }
    }

    @include media(lg) {
        .progress-bar__step-text {
            white-space: unset;
            max-width: 65px;
        }
    }

    @include media(xl) {
        .progress-bar__step-text {
            white-space: nowrap;
            max-width: unset;
        }
    }
}

.order-cancellation {
    &__buttons {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: $space * 2;
        margin-left: auto;
        width: 100%;
    }

    .progress-bar__step-text {
        display: none;
    }

    @include media(xl) {
        &__buttons {
            width: auto;
            margin-top: 0;
        }

        .progress-bar {
            justify-content: center;
            width: auto;

            &__step-text {
                display: block;
            }
        }

        .tooltip {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    @include media(xxl) {
        .progress-bar > i {
            margin: 0 ($space * 8);
        }
    }
}

;@import "sass-embedded-legacy-load-done:363";