.pagination {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-top: $space * 3;

    @include media(xl) {
        margin-top: 0;
        width: auto;
    }

    &__item {
        @include border($color: $theme-color);
        border-radius: $border-radius;
        color: $theme-color;
        font-weight: $font-weight--bold;
        margin-left: $space;
        padding: $space ($space * 2);

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            color: #fff;
            text-decoration: none;
        }

        &--last,
        &--prev,
        &--current {
            background-color: $theme-color;
            color: #fff;
        }

        &--dots {
            background-color: transparent;
            border: 0;
            color: $base-text-color;
            font-weight: $font-weight--base;
            padding: $space 0;
        }

        &--inactive {
            background-color: $grey--light;
            border-color: $grey;
            color: $grey--dark;
        }
    }
}

.pagination-top {
    display: flex;
    align-items: center;

    &__item {
        padding-left: 3px;
        padding-right: 3px;
    }
}

;@import "sass-embedded-legacy-load-done:898";