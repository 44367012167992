.ca-content {
    &--overview {
        display: none;
    }

    &--shopping-list {
        input[name="newName"] {
            height: 40px;
            padding-top: $space * 2;
            padding-bottom: $space * 2;
        }

        input.ng-invalid.ng-touched {
            background-position: calc(100% - 10px) -93px;
        }
    }
}

@include media(lg) {
    .ca-content {
        &--overview {
            display: block;
        }
    }
}

.order-details {
    background-color: white;
    box-shadow: $shadow1;

    &--featured {
        @include border($where: 'left', $width: 5px, $color: $blue);
    }

    &__loader-wrapper {
        padding-bottom: 25px;

        .fa-spin {
            color: $theme-color;
            font-size: 30px;
            left: calc(50% - 21px);
            position: relative;
            top: calc(50% - 21px);
        }
    }
}

.order-detail {
    &.collapsed {
        display: none;
    }

    &--show {
        cursor: pointer;

        &.collapsed .fa-chevron-up {
            transform: rotate(180deg);
        }
    }

    &--images,
    &--more {
        margin: 0 auto;
        text-align: center;
        justify-content: center;

        @include media(xxl) {
            float: right;
            text-align: right;
        }
    }
}

.more-items-container {
    position: relative;
    color: white;

    img {
        width: 80px;
        height: 80px;
    }
}

.more-items-overflow {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 80px;
    height: 80px;
    display: none;
}

.more-items-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: $font-size--medium;
    font-weight: $font-weight--bold;
    display: none;
}

.order-image--3 {
    & .more-items-overflow,
    & .more-items-text {
        display: block;
    }
}

.status-progress,
.status-progress__value {
    background-color: $grey;
    border-radius: 3px;
    height: 8px;
}

.status-progress__value {
    background-color: $green;
    width: 0;

    &--bestellt {
        width: 25%;
    }

    &--versandt {
        width: 50%;
    }

    &--inzustellung {
        width: 75%;
    }

    &--zugestellt {
        width: 100%;
    }
}

.items_per_page {
    width: 100px;
}

.ca-list {
    &--summary {
        width: 250px;

        &-big {
            width: 100%;
        }
    }

    &--image {
        min-width: 48px !important;
        width: 48px;
    }

    &--select {
        width: 120px;

        @include media(md) {
            width: 150px;
        }

        @include media(xxl) {
            width: 120px;
        }
    }
}

.ca-block {
    background: white;

    img {
        max-width: 100px;
    }

    &--shadowed {
        box-shadow: $shadow6;
    }

    &--inner {
        @include media(lg) {
            height: 430px;
        }

        @include media(xl) {
            height: 319px;
        }
    }

    &--spacer {
        height: 131px;
    }

    &--ellipsis {
        @include media(lg) {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &--empty {
        @include media(lg) {
            height: 479px;
        }
    }

    @include media(xl) {
        height: 367px;
    }

    &--no-lists {
        padding: 80px 0 120px !important;

        @include media(xl) {
            padding: 100px 0 150px !important;
        }
    }
}

;@import "sass-embedded-legacy-load-done:617";