@include media(md) {
    .o-expandable-product-list {
        &__products {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 40px;
        }

        &__product-container {
            display: none;
            padding: ($space * 2) ($space * 2) ($space * 8) ($space * 2);
            &.-md {
                display: block;
            }
        }

        &__product-container:nth-child(2n),
        &__product-container:nth-child(2n + 9),
        &__product-container.-expanded:nth-child(2n) {
            display: none;
        }

        &__product-container.-expanded:nth-child(2n - 1) {
            display: block;
        }

        &__cta.-show-more {
            margin-top: $space * 6;
            padding-bottom: $space * 14;
            margin-bottom: $space * 14;
        }

        &__cta.-show-all {
            margin-top: $space * 14;
            padding-top: $space * 14;
            margin-bottom: $space * 14;
        }

        &__show-more,
        &__show-all {
            width: 33.333%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1157";