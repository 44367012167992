@include media(xxl) {
    .t-cart__main-container {
        .t-cart-empty {
            &__content {
                width: 660px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:865";