.popular-category {
    min-height: 175px;
    overflow: hidden;

    &__button {
        bottom: $space * 2;
        position: absolute;
        right: $space * 2;
    }

    &__image {
        background: no-repeat center;
        background-size: contain;
        bottom: 0;
        height: 130px;
        left: 0;
        position: absolute;
    }

    &__container {
        margin: 0 (-$space);
    }
}

.cat-brand-logo {
    height: 36px;

    &__container {
        @include media(lg) {
            justify-content: center;
        }
    }
    & img {
        max-height: 36px;

        @include media(lg) {
            max-height: 24px;
        }
        @include media(xl) {
            max-height: 36px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:794";