.m-product-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: $space * 4 0;

    &__logo {
        margin-right: $space * 4;
    }
}

;@import "sass-embedded-legacy-load-done:1188";