@include media(md) {
    .o-item-bottom-action {
        padding-left: $space * 4;
        margin-left: $space * 4;

        &:first-child {
            padding-left: 0;
            margin-left: 0;
            border: none;
        }

        &__btn-text {
            margin-left: $space * 2;
        }

        &__btn {
            height: $space * 3;
            & [class^=icon--] {
                width: $space * 3;
                height: $space * 3;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:832";