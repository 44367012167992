.payment-method {
    @include border($where: bottom);
    display: flex;
    padding: $space * 3;
    width: 100%;

    &__title {
        font-size: $font-size--regular-plus;
        font-weight: $font-weight--bold;
    }

    &__badge {
        width: 75px;
        height: 15px;
        padding: $space 0;
        color: #fff;
        display: inline-block;
        background-color: $theme-color;
        border-radius: $border-radius;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
        text-align: center;
        line-height: 0.5;
    }

    &__icons {
        display: flex;
    }

    &__content {
        padding: $space 0;
        font-size: $font-size--regular;
    }

    &__button {
        &:hover {
            text-decoration: underline;
        }

        color: $theme-color;
        padding: 0;
        border: none;
        background: none;
    }

    &__form-wrapper {
        margin-top: $space * 3;
        padding: $space * 3;
        background-color: $grey--light;
    }

    &__form-row {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__form-label {
        font-weight: $font-weight--bold;
        margin-bottom: $space * 2;
    }

    &__form-input,
    &__form-input-container-payone {
        margin-bottom: $space * 2;
    }

    &__form-input-container-payone {
        @include border($color: $grey, $width: 1px);
        padding: 0 $space;
        background-color: white;
    }

    &__form-input-select {
        background-color: white;

        @include media(lg) {
            height: 40px;
            padding-top: $space;

            &::after {
                top: 6px;
            }
        }
    }

    &__form-info {
        margin-top: $space * 2;
        padding-left: $space * 2;
        font-size: $font-size--medium-plus;
    }

    &__button--save {
        margin-top: $space * 3;
    }

    @include media(md) {
        &__badge {
            margin-left: $space;
        }

        &__button--save {
            margin-top: 0;
        }
    }

    @include media(xl) {
        &__form-row {
            flex-direction: row;
        }

        &__form-label {
            display: flex;
            align-items: center;
            width: percentage(calc(1 / 4));
        }

        &__form-input,
        &__form-input-container-payone {
            width: percentage(calc(3 / 4));
        }

        &__form-tooltip {
            margin-left: percentage(calc(1 / 4));
        }
    }
}

;@import "sass-embedded-legacy-load-done:697";