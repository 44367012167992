$logo-max-height-mobile: 55px;

.brandpage {
    &__logo-container--big {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 110px;
        height: $logo-max-height-mobile;
        background-color: #fff;
        z-index: 1;
    }

    &__logo-image--big {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        img {
            max-height: $logo-max-height-mobile;
            padding: $space;
        }
    }
}

.hero-banner {
    position: relative;
    overflow: hidden;
    background: center/cover no-repeat;

    &__full-width {
        position: relative;
        @include grow-outside-container;
        margin-bottom: $space * 14;

        .brandpage__banner {
            aspect-ratio: 4 / 3;
        }

        .brandpage__slim-banner {
            background: top/cover no-repeat;
            aspect-ratio: 3 / 1;
        }
    }

    &__content--bg {
        background-color: $grey--darker;

        @include grow-outside-container;
    }

    &__eyecatcher-container {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 93px;
    }

    &__content--container {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: $space * 4;
    }

    &__text--container {
        display: flex;
        flex-direction: column;
    }

    &__text--preheadline {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
        margin-bottom: $space;
        word-wrap: break-word;
    }

    &__text--headline {
        font-size: $font-size--xlarge-plus;
        line-height: 43px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-bottom: $space;
        word-wrap: break-word;
    }

    &__btn--container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
        margin-top: $space * 3;
    }

    &__btn {
        height: 48px;
        width: 100%;
        max-width: 300px;
        font-size: $font-size--medium;
        margin-bottom: $space * 2;
    }
}

.v2.hero-banner {
    &__full-width {
        height: 340px;
        background-color: $grey--darker;
        margin-bottom: $space * 14;
    }
}

//TODO: .hero-banner-v2 is for A/B test hero-banner-BOSS-32563. Clean-up ticket/BOSS-33057
.hero-banner-v2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: $space * 4;

    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 75%;
        padding: ($space * 4) 0;
    }

    &__headline {
        margin-bottom: $space * 3;
    }

    &__image {
        width: 100%;

        &--ratio {
            aspect-ratio: 3/2;
            width: 510px;
            height: 340px;
        }

        &--secondary {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1000";