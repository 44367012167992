.m-add-to-cart-form-v2 {
    $s2: $space * 2;
    $s4: $space * 4;

    &__price-quantity {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    &__price {
        flex: auto;
    }

    &__stock {
        margin-top: $s2;
        margin-bottom: $s2;
    }

    &__stock-quantity {
        margin: $s4 0 0 0;
    }

    &__energy-label {
        width: 100%;
        margin-top: $s4;
    }
}

;@import "sass-embedded-legacy-load-done:1199";