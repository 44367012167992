.order-history-applied-filters {
    display: flex;
    align-items: center;
    margin-top: $space * 6;

    &__list {
        display: flex;
        margin-left: $space * 2;
    }

    .order-history-applied-filters__tag {
        padding: $space;
    }

    &__count {
        margin-top: $space * 4;
    }
}
.order-history-next-btn[disabled],
.order-history-prev-btn[disabled],
.order-history-next-btn[disabled]:hover,
.order-history-prev-btn[disabled]:hover {
    cursor: not-allowed;
    background-color: transparent;
}

.order-history-next-btn {
    margin-left: $space * 2;
}

;@import "sass-embedded-legacy-load-done:730";