.brands {
    .slider__content {
        margin: ($space * 3) (-$space * 2) 0 (-$space * 2);
        justify-content: flex-start;
        align-items: center;
    }

    &__item {
        flex: 1;
        min-width: 100px;

        picture::after {
            content: "";
            display: table;
            clear: both;
        }

        img {
            display: inline-block;
            max-height: 40px;
            width: auto;
        }

        a {
            display: flex;
            padding: $space * 2;
            height: 60px;
            justify-content: center;
            align-items: center;
        }

        picture img {
            float: left;
        }
    }

    &__more-link {
        flex: 1;
        min-width: 100px;
        padding: ($space * 2) 0 ($space * 3) 0;
        display: flex;
        align-items: center;
        text-align: left;
        font-weight: $font-weight--bold;
        line-height: 1;

        &::after {
            content: $fa-var-chevron-right;
            font-family: "FontAwesome";
            margin-left: $space;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

@include media(lg) {
    .brands {
        .slider__content {
            margin: ($space * 3) 0;
            flex-wrap: wrap;
        }

        &__item,
        &__more-link {
            padding: $space * 2;
            min-width: percentage(calc(1 / 8));
            max-width: percentage(calc(1 / 8));
        }
    }
}

;@import "sass-embedded-legacy-load-done:252";