.o-header-slider {
    flex-shrink: 0;
    width: inherit;
    position: relative;

    //a fix for touch events which are not raised in ios devices from the second slide onward
    &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &__top-container {
        position: relative;
    }

    &__container {
        overflow: hidden;
        @include grow-outside-container;
    }

    &__brand-logo-wrapper {
        padding: $space;
        background-color: white;
        position: absolute;
        top: 0;
        left: $space * 4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 40px;
    }

    &__brand-logo {
        max-height: 100%;
        object-fit: contain;
    }

    &__banner-img {
        aspect-ratio: 2 / 1;
        width: 100%;
        display: block;
        object-fit: cover;
    }

    .o-eye-catcher {
        position: absolute;
        right: 0;
        bottom: $space * 4;
    }

    &__remaining-days {
        @include text-variant(small-plus);
        margin-bottom: $space;
        text-transform: uppercase;
    }

    &__campaign-title {
        margin-bottom: $space * 2;
        @include line-clamp(2);
        padding-bottom: 1px; // fix font cutting(eg: g) cause of line-height convention
    }

    &__campaign-subtitle {
        display: none;
    }

    &__btn {
        width: 100%;
        max-width: 300px;
        @include text-variant(medium-plus);
    }

    &__bottom-container {
        width: 100%;
        position: relative;
        padding-bottom: 9px;
        aspect-ratio: 2 / 1;

        &::before,
        &::after {
            content: "";
            position: absolute;
            height: 9px;
            display: flex;
            width: 100%;
            z-index: $z1;
            left: 0;
        }

        &::before {
            background-image: url(/images/svg/brush_grey-top.svg);
            top: -8px;
        }

        &::after {
            background-image: url(/images/svg/brush_grey-bottom.svg);
            bottom: 1px;
        }
    }

    &__bottom-container-inner {
        text-align: center;
        background-color: $grey--darker;
        padding: 0 $space * 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}


;@import "sass-embedded-legacy-load-done:1134";