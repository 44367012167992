.product-description-v2 {
    li,
    p {
        font-size: $font-size--medium;
        line-height: $line-height--large;
    }

    h2 {
        font-size: 16px;
        line-height: 16px;
    }
}

.recycling-images {
    &__container {
        margin: ($space * 8) 0;
    }

    &__image-block {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &__image {
        margin-right: $space * 14;

        img {
            margin: ($space * 2) 0;
            max-height: $space * 16;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1327";