/* ------------------------------------ *\
    BREADCRUMBS
\* ------------------------------------ */

.breadcrumbs {
    @include border($where: bottom);
    margin-bottom: $space * 2;
    padding: ($space * 2) 0;
    display: flex;
    overflow: auto;

    &__item {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    &.mobile {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;

        li[itemprop="itemListElement"] {
            display: flex;
        }
        span[itemprop="name"] {
            white-space: nowrap;
        }
    }
}

;@import "sass-embedded-legacy-load-done:944";