/* Tree */
.cat-tree {
    background-color: $grey--light;
    left: 100%;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 0;
    height: calc(100vh - 148px);

    @include media(lg) {
        background-color: white;
        height: auto;
        left: 0;
        overflow: visible;
        position: relative;
        width: percentage(calc(1 / 3));
        height: auto;
    }

    @include media(xl) {
        width: percentage(calc(1 / 4));
    }

    .show-categories & {
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        left: -100%;
        top: 0;
        z-index: $z5;

        &__header,
        &__footer,
        &__content {
            background-color: white;
            position: fixed;
            margin-right: 48px;
            width: calc(100% - 48px);
        }

        &__header {
            height: 48px;
        }

        &__footer {
            top: calc(var(--vh, 1vh) * 100 - 124px);
            height: 70px;
            box-shadow: $shadow8;
        }

        &__content {
            top: 48px;
            height: calc(var(--vh, 1vh) * 100 - 172px);
            width: calc(100% - 48px);
            overflow: auto;
        }

        .cat-tree-cancel {
            width: calc(100% - 30px);
            margin: $space * 3;
        }

        .close-cat-tree {
            display: flex;
        }
    }

    &__container {
        background-color: white;
        overflow: hidden;

        @include border($where: 'bottom');
    }

    &__item {
        cursor: pointer;
        position: relative;
        & > .fa:last-child {
            right: $space;
        }
    }

    &__image {
        display: none;
        flex: none;
        height: 32px;
        width: 32px;

        @include media(lg) {
            display: block;
        }
    }

    &__btn {
        background-color: #fff;
        border-bottom: 0;

        & > .fa {
            right: $space * 2;
            @include media(md) {
                right: $space * 2;
            }
        }
    }

    .close-cat-tree {
        background-color: $grey--light;
        position: fixed;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        display: none;
        border: 0;
        border-radius: 0;
    }
}

.cat-box {
    height: 100%;

    &__container {
        margin: 0 (-$space);
    }

    &__image {
        max-width: 47px;
        @include media(xxl) {
            max-width: 90px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:799";