.brush-overlay {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-image: url(/images/svg/brush-top.svg);
        height: 9px;
        display: flex;
        width: 100%;
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        background-image: url(/images/svg/brush-bottom.svg);
        height: 9px;
        display: flex;
        width: 100%;
        bottom: -1px;
        z-index: 1;
    }

    &--top {
        position: relative;
    }

    &--top::before {
        content: "";
        position: absolute;
        background-image: url(/images/svg/brush-top.svg);
        height: 9px;
        display: flex;
        width: 100%;
        z-index: 1;
    }

    &--bottom {
        position: relative;
    }

    &--bottom::after {
        content: "";
        position: absolute;
        background-image: url(/images/svg/brush-bottom.svg);
        height: 9px;
        display: flex;
        width: 100%;
        bottom: -1px;
        z-index: 1;
    }
}

;@import "sass-embedded-legacy-load-done:980";