.o-featured-product {
    display: flex;
    flex-direction: column;
    box-shadow: $shadow9;
    border-radius: 2px;
    background-color: white;

    &__image {
        height: 249px;

        &--large,
        &--small {
            img {
                max-width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
                margin: 0 auto;
            }
        }
    }

    &__content {
        padding: $space * 4;
        height: 100%;
        justify-content: center;
        background: white;
    }


    &__header {
        margin-bottom: $space * 4;
        font-size: $font-size--xlarge;
        line-height: 43px;
    }

    &__button {
        margin-top: $space * 6;
        flex-grow: 0;
    }

    &__list {
        position: relative;
    }

    &__list--item {
        padding-left: $space * 4;

        &::before {
            position: absolute;
            content: '■';
            font-family: $font-family--font-awesome;
            left: 0;
            color: $theme-color;
            font-size: $font-size--medium-plus;
            line-height: $line-height--large-plus;
        }

        .o-featured-product__list--item--text {
            line-height: 26px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1341";