.m-paypal-instalment-banner {
    &--white {
        background-color: white;
    }

    &--product {
        margin-top: $space * 2;
        padding: $space * 4;
        border-radius: $border-radius;
    }

    &--cart {
        padding: ($space * 2) ($space * 4);
    }

    &--payment {
        margin: ($space * 3) (-$space * 3);
        padding: ($space * 3) ($space * 3) ($space * 2);
    }
}

;@import "sass-embedded-legacy-load-done:902";