@include media(xxl) {
    .o-header-slider {
        &::after {
            background-color: rgba(0, 0, 0, 0.7);
        }

        &__brand-logo-wrapper {
            bottom: $space * 20;
            width: 160px;
            height: 80px;
        }

        &__brand-logo {
            max-height: 100%;
        }

        &__brand-logo {
            width: 150px;
        }

        .o-eye-catcher {
            right: $space * 14;
        }

        &__remaining-days {
            @include text-variant(large);
            margin-bottom: $space * 2;
        }

        &__campaign-title {
            margin-bottom: $space * 4;
        }

        &__campaign-subtitle {
            margin-bottom: $space * 6;
        }

        &__btn {
            width: 305px;
        }

        &__bottom-container-inner {
            padding: 0 20% 0 ($space * 38);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1140";