/* ------------------------------------ *\
    SUB-HEADLINE
\* ------------------------------------ */

.subheadline {
    @include border($where: 'top');

    font-size: $font-size--regular-plus;
    line-height: $line-height--regular;
    position: relative;
    padding: ($space * 3) 0 ($space * 3) ($space * 5);
    cursor: pointer;

    &.collapsed {
        .subheadline__icon-right::before {
            content: $fa-var-angle-down;
        }
    }

    [class^="icon--"] {
        font-size: $font-size--medium;
    }

    &__icon-left,
    &__icon-right { position: absolute; }

    &__icon-left {
        display: none;
        top: 18px;
        left: $space;
        color: $grey;
        font-size: $font-size--medium;
        line-height: $line-height--regular;
    }

    &__icon-right {
        display: block;
        top: 50%;
        transform: translateY(-50%);
        right: $space * 2;
        font-size: $font-size--large;
        line-height: $line-height--large;
    }

    @include media(lg) {
        border-top: 0;
        font-family: $font-family--main;
        font-size: $font-size--large;
        line-height: $line-height--large;
        padding-top: $space * 3;
        padding-bottom: $space * 2;
        margin-top: $space * 4;
        cursor: auto;

        &__icon-left {
            display: block;
        }

        &__icon-right {
            display: none;
        }

        &.collapsed {
            @include border($where: 'bottom');
        }
    }
}

;@import "sass-embedded-legacy-load-done:920";