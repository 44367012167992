//Refactors: styles/v1/components/_product-specs.scss

.o-product-specs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0 ($space * 2);

    img {
        max-width: 100%;
        float: right;
    }

    &--border-bottom {
        @include border('bottom');
    }
}
;@import "sass-embedded-legacy-load-done:1291";