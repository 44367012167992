.t-homepage {
    &__user-lists {
        &-title {
            margin-bottom: 8 * $space;
        }

        &-container {
            display: grid;
            row-gap: 8 * $space;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1469";