/* ------------------------------------ *\
    PRODUCT Image v2
\* ------------------------------------ */

.m-product-image-v2 {
    $image-size-widget: 227px;

    padding: 0;
    position: relative;

    &__lazy-load-container {
        display: block;
        overflow: hidden;
        position: relative;
    }

    &__image {
        display: block;
        left: 0;
        margin: 0 auto;
        top: 0;
        width: $image-size-widget;
        min-height: $image-size-widget;
        min-width: $image-size-widget;

        &--medium {
            width: $image-size-m;
            min-height: $image-size-m;
            min-width: $image-size-m;
        }
    }

    .slider__content & {
        max-width: 100%;
        min-width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:1178";