.m-collapsible-v2 {
    &__target {
        display: block;
    }

    &__target.collapsed {
        display: none;
    }

    &__target:not(.m-collapsible-v2__target) {
        &.collapsed {
            display: block;
        }
    }

    &__source {
        .fa-angle-up {
            color: $blue;
            font-size: $font-size--xlarge-plus;
            font-weight: $font-weight--bold;
        }
    }

    &__source.collapsed {
        cursor: pointer;
        .fa-angle-up {
            display: block;
            transform: rotate(180deg);
        }
    }

    &__source:not(.m-collapsible-v2__source) {
        cursor: initial;
        .fa-angle-up {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1186";