/* ------------------------------------ *\
    TOOLTIPS
\* ------------------------------------ */

.tooltip {
    @include border;
    background-color: #fff;
    box-shadow: $shadow2;
    left: 0;
    right: 0;
    max-width: 400px;
    min-width: 200px;
    padding: 5px 7px;
    position: absolute;
    top: calc(100% + 3px);
    z-index: $z4;

    &::before,
    &::after {
        content: "";
        left: calc(50% - 1px);
        margin-left: -7px;
        position: absolute;
        top: -14px;
        z-index: $z4;
        @include border($width: 7px, $color: transparent);
    }

    &::before {
        border-bottom-color: $grey--darker;
    }

    &::after {
        border-bottom-color: #fff;
    }

    &--position-right {
        top: calc(25%);
        left: calc(100% + 3px);

        &::before {
            top: calc(50% - 7px);
            left: -8px;
        }
    }

    &--position-left {
        top: calc(25%);
        left: -403px;

        &::before {
            top: calc(50% - 7px);
            right: -15px;
            left: auto;
        }
    }

    &--info,
    &--error,
    &--warning {
        color: #fff;
    }

    &--info {
        background-color: $theme-color;
        border-color: $theme-color;

        &::before,
        &::after {
            border-bottom-color: $theme-color;
        }

        a {
            color: white;
            text-decoration: underline;
        }

        &.tooltip--position-right::before {
            border-right-color: $theme-color;
            border-bottom-color: transparent;
        }

        &.tooltip--position-left::before {
            border-bottom-color: transparent;
            border-left-color: $theme-color;
        }
    }

    &--error {
        background-color: $red;
        border-color: $red;

        &::before,
        &::after {
            border-bottom-color: $red;
        }
    }

    &--success {
        background-color: $green;
        border-color: $green;

        &::before,
        &::after {
            border-bottom-color: $green;
        }
    }

    &--warning {
        background-color: $yellow;
        border-color: $yellow;

        &::before,
        &::after {
            border-bottom-color: $yellow;
        }
    }

    &--help {
        color: $grey--darker;
    }

    &--left {
        &::before,
        &::after {
            left: $space * 3;
        }
    }

    &--trigger {
        .tooltip {
            color: $grey--darker;
            display: none;
        }

        &:hover .tooltip {
            display: block;
        }
    }

    &--flipped {
        bottom: calc(100% + 3px);
        top: unset;

        &::before,
        &::after {
            bottom: -16px;
            top: unset;
            transform: rotate(180deg);
        }
    }

    &--wrap {
        white-space: normal;
    }
}

;@import "sass-embedded-legacy-load-done:956";