.ca-company-size-nudge-modal {
    border-radius: $space;

    &__form,
    &__success {
        padding: 0 $space*6 $space*6;
    }

    &__header {
        text-align: center;
    }

    &__subtext {
        padding: $space*3 0 $space*6;
    }

    &__dropdown {
        padding-bottom: $space*4;

        label {
            margin-bottom: $space;
        }
    }

    &__button {
        width: 100%;
    }

    &__success {
        display: none;

        .icon--check {
            color: $green;
            font-size: 26px;

            &::before {
                padding-bottom: $space*3;
            }
        }

        &.show {
            display: block;
            text-align: center;
        }
    }

    &__success-subtext {
        padding: $space*3 0 $space*4;
    }
}


;@import "sass-embedded-legacy-load-done:748";