@include media(md) {
    .a-divider {
        &__line {
            &--left {
                margin-left: $space * 14;
                margin-right: $space * 8;
            }

            &--right {
                margin-left: $space * 8;
                margin-right: $space * 14;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:960";